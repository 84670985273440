import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as benefitStyles from "./benefits-snapshot.module.scss"

const BenefitsSnapshot = ({ pageContext, location }) => {
  const pageTitle = "Benefits Snapshot"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <h1>Benefits Snapshot</h1>
        <ul className={benefitStyles.list}>
          <li>Healthcare, vision, prescription, dental, and life insurance</li>
          <li>401k</li>
          <li>Generous time off benefits</li>
          <li>Continuing education and development</li>
          <li>Life/work balance</li>
          <li>Product discounts</li>
          <li>Referral award program</li>
        </ul>

        <p><a class="CTAblack" href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst=" title="View Open Positions">View Open Positions</a></p>
      </div>
    </Layout>
  )
}

export default BenefitsSnapshot
